@charset "UTF-8";
/*
 * easy-autocomplete
 * jQuery plugin for autocompletion
 * 
 * @author Łukasz Pawełczak (http://github.com/pawelczak)
 * @version 1.3.5
 * Copyright  License: 
 */
.easy-autocomplete {
  position: relative; }

.easy-autocomplete input {
  border-color: #ccc;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
  color: #555;
  float: none;
  padding: 6px 12px; }

.easy-autocomplete input:hover, .easy-autocomplete input:focus {
  box-shadow: none; }

.easy-autocomplete a {
  display: block; }

.easy-autocomplete.eac-blue-light input:hover, .easy-autocomplete.eac-blue-light input:focus {
  border-color: #66afe9;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(102, 175, 233, 0.6); }

.easy-autocomplete.eac-blue-light ul {
  border-color: #66afe9;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(102, 175, 233, 0.6); }

.easy-autocomplete.eac-blue-light ul li, .easy-autocomplete.eac-blue-light ul .eac-category {
  border-color: #66afe9; }

.easy-autocomplete.eac-blue-light ul li.selected, .easy-autocomplete.eac-blue-light ul .eac-category.selected {
  background-color: #ecf5fc; }

.easy-autocomplete.eac-green-light input:hover, .easy-autocomplete.eac-green-light input:focus {
  border-color: #41DB00;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(146, 237, 107, 0.6); }

.easy-autocomplete.eac-green-light ul {
  border-color: #41DB00;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(146, 237, 107, 0.6); }

.easy-autocomplete.eac-green-light ul li, .easy-autocomplete.eac-green-light ul .eac-category {
  border-color: #41DB00; }

.easy-autocomplete.eac-green-light ul li.selected, .easy-autocomplete.eac-green-light ul .eac-category.selected {
  background-color: #9eff75; }

.easy-autocomplete.eac-red-light input:hover, .easy-autocomplete.eac-red-light input:focus {
  border-color: #ff5b5b;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(255, 90, 90, 0.6); }

.easy-autocomplete.eac-red-light ul {
  border-color: #ff5b5b;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(255, 90, 90, 0.6); }

.easy-autocomplete.eac-red-light ul li, .easy-autocomplete.eac-red-light ul .eac-category {
  border-color: #ff5b5b; }

.easy-autocomplete.eac-red-light ul li.selected, .easy-autocomplete.eac-red-light ul .eac-category.selected {
  background-color: #ff8e8e; }

.easy-autocomplete.eac-yellow-light input:hover, .easy-autocomplete.eac-yellow-light input:focus {
  border-color: #ffdb00;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(255, 231, 84, 0.6); }

.easy-autocomplete.eac-yellow-light ul {
  border-color: #ffdb00;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(255, 231, 84, 0.6); }

.easy-autocomplete.eac-yellow-light ul li, .easy-autocomplete.eac-yellow-light ul .eac-category {
  border-color: #ffdb00; }

.easy-autocomplete.eac-yellow-light ul li.selected, .easy-autocomplete.eac-yellow-light ul .eac-category.selected {
  background-color: #ffe233; }

.easy-autocomplete.eac-dark-light input:hover, .easy-autocomplete.eac-dark-light input:focus {
  border-color: #333;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(55, 55, 55, 0.6); }

.easy-autocomplete.eac-dark-light ul {
  border-color: #333;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(55, 55, 55, 0.6); }

.easy-autocomplete.eac-dark-light ul li, .easy-autocomplete.eac-dark-light ul .eac-category {
  border-color: #333; }

.easy-autocomplete.eac-dark-light ul li.selected, .easy-autocomplete.eac-dark-light ul .eac-category.selected {
  background-color: #4d4d4d;
  color: #fff; }

.easy-autocomplete.eac-dark {
  color: #fff; }

.easy-autocomplete.eac-dark input {
  background-color: #404040;
  border-radius: 4px;
  box-shadow: 0;
  color: #f6f6f6; }

.easy-autocomplete.eac-dark input:hover, .easy-autocomplete.eac-dark input:focus {
  border-color: #333;
  box-shadow: 0; }

.easy-autocomplete.eac-dark ul {
  border-color: #333; }

.easy-autocomplete.eac-dark ul li, .easy-autocomplete.eac-dark ul .eac-category {
  background-color: #404040;
  border-color: #333; }

.easy-autocomplete.eac-dark ul li.selected, .easy-autocomplete.eac-dark ul .eac-category.selected {
  background-color: #737373;
  color: #f6f6f6; }

.easy-autocomplete.eac-dark-glass {
  color: #fff; }

.easy-autocomplete.eac-dark-glass input {
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  box-shadow: 0;
  color: #f6f6f6; }

.easy-autocomplete.eac-dark-glass input:hover, .easy-autocomplete.eac-dark-glass input:focus {
  border-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0; }

.easy-autocomplete.eac-dark-glass ul {
  border-color: rgba(0, 0, 0, 0.8); }

.easy-autocomplete.eac-dark-glass ul li, .easy-autocomplete.eac-dark-glass ul .eac-category {
  background-color: rgba(0, 0, 0, 0.8);
  border-color: rgba(0, 0, 0, 0.8); }

.easy-autocomplete.eac-dark-glass ul li.selected, .easy-autocomplete.eac-dark-glass ul .eac-category.selected {
  background-color: rgba(64, 64, 64, 0.8);
  color: #f6f6f6; }

.easy-autocomplete.eac-dark-glass ul li:last-child, .easy-autocomplete.eac-dark-glass ul .eac-category:last-child {
  border-radius: 0 0 4px 4px; }

.easy-autocomplete.eac-blue {
  color: #fff; }

.easy-autocomplete.eac-blue input {
  background-color: #6d9ed1;
  border-radius: 4px;
  box-shadow: 0;
  color: #f6f6f6; }

.easy-autocomplete.eac-blue input::-webkit-input-placeholder {
  color: #f6f6f6; }

.easy-autocomplete.eac-blue input:-moz-placeholder {
  color: #f6f6f6; }

.easy-autocomplete.eac-blue input::-moz-placeholder {
  color: #f6f6f6; }

.easy-autocomplete.eac-blue input:-ms-input-placeholder {
  color: #f6f6f6; }

.easy-autocomplete.eac-blue input:hover, .easy-autocomplete.eac-blue input:focus {
  border-color: #5A91CB;
  box-shadow: 0; }

.easy-autocomplete.eac-blue ul {
  border-color: #5A91CB; }

.easy-autocomplete.eac-blue ul li, .easy-autocomplete.eac-blue ul .eac-category {
  background-color: #6d9ed1;
  border-color: #5A91CB; }

.easy-autocomplete.eac-blue ul li.selected, .easy-autocomplete.eac-blue ul .eac-category.selected {
  background-color: #94b8dd;
  color: #f6f6f6; }

.easy-autocomplete.eac-yellow {
  color: #333; }

.easy-autocomplete.eac-yellow input {
  background-color: #ffdb7e;
  border-color: #333;
  border-radius: 4px;
  box-shadow: 0;
  color: #333; }

.easy-autocomplete.eac-yellow input:hover, .easy-autocomplete.eac-yellow input:focus {
  border-color: #333;
  box-shadow: 0; }

.easy-autocomplete.eac-yellow ul {
  border-color: #333; }

.easy-autocomplete.eac-yellow ul li, .easy-autocomplete.eac-yellow ul .eac-category {
  background-color: #ffdb7e;
  border-color: #333; }

.easy-autocomplete.eac-yellow ul li.selected, .easy-autocomplete.eac-yellow ul .eac-category.selected {
  background-color: #ffe9b1;
  color: #333; }

.easy-autocomplete.eac-purple {
  color: #333; }

.easy-autocomplete.eac-purple input {
  background-color: #d6d1e7;
  border-color: #b8afd5;
  box-shadow: 0;
  color: #333; }

.easy-autocomplete.eac-purple input:hover, .easy-autocomplete.eac-purple input:focus {
  border-color: #333;
  box-shadow: 0; }

.easy-autocomplete.eac-purple ul {
  border-color: #333; }

.easy-autocomplete.eac-purple ul li, .easy-autocomplete.eac-purple ul .eac-category {
  background-color: #d6d1e7;
  border-color: #333; }

.easy-autocomplete.eac-purple ul li.selected, .easy-autocomplete.eac-purple ul .eac-category.selected {
  background-color: #ebe8f3;
  color: #333; }

.easy-autocomplete.eac-bootstrap input {
  border-color: #ccc;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  color: #555;
  padding: 6px 12px; }

.easy-autocomplete-container {
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 2; }

.easy-autocomplete-container ul {
  background: none repeat scroll 0 0 #ffffff;
  border-top: 1px dotted #ccc;
  display: none;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  position: relative;
  top: -1px; }

.easy-autocomplete-container ul li, .easy-autocomplete-container ul .eac-category {
  background: inherit;
  border-color: #ccc;
  border-image: none;
  border-style: solid;
  border-width: 0 1px;
  display: block;
  font-size: 14px;
  font-weight: normal;
  padding: 4px 12px; }

.easy-autocomplete-container ul li:last-child {
  border-radius: 0 0 2px 2px;
  border-width: 0 1px 1px; }

.easy-autocomplete-container ul li.selected {
  background: none repeat scroll 0 0 #ebebeb;
  cursor: pointer; }

.easy-autocomplete-container ul li.selected div {
  font-weight: normal; }

.easy-autocomplete-container ul li div {
  display: block;
  font-weight: normal;
  word-break: break-all; }

.easy-autocomplete-container ul li b {
  font-weight: bold; }

.easy-autocomplete-container ul .eac-category {
  font-color: #aaa;
  font-style: italic; }

.eac-description .eac-item span {
  color: #aaa;
  font-style: italic;
  font-size: 0.9em; }

.eac-icon-left .eac-item img {
  margin-right: 4px;
  max-height: 30px; }

.eac-icon-right .eac-item {
  margin-top: 8px;
  min-height: 24px;
  position: relative; }

.eac-icon-right .eac-item img {
  margin-left: 4px;
  max-height: 30px;
  position: absolute;
  right: -4px;
  top: -8px; }

/*# sourceMappingURL=easy-autocomplete.min.css.map */
