body.compensate-for-scrollbar {
  overflow: hidden; }

.fancybox-active {
  height: auto; }

.fancybox-iosfix {
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  width: 100%; }

.fancybox-is-hidden {
  left: -9999px;
  margin: 0;
  position: absolute !important;
  top: -9999px;
  visibility: hidden; }

.fancybox-container {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif;
  height: 100%;
  left: 0;
  position: fixed;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  width: 100%;
  z-index: 99992; }

.fancybox-container * {
  box-sizing: border-box; }

.fancybox-bg, .fancybox-inner, .fancybox-outer, .fancybox-stage {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0; }

.fancybox-outer {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto; }

.fancybox-bg {
  background: #1e1e1e;
  opacity: 0;
  transition-duration: inherit;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71); }

.fancybox-is-open .fancybox-bg {
  opacity: .87;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1); }

.fancybox-caption, .fancybox-infobar, .fancybox-navigation .fancybox-button, .fancybox-toolbar {
  direction: ltr;
  opacity: 0;
  position: absolute;
  transition: opacity .25s,visibility 0s linear .25s;
  visibility: hidden;
  z-index: 99997; }

.fancybox-show-caption .fancybox-caption, .fancybox-show-infobar .fancybox-infobar, .fancybox-show-nav .fancybox-navigation .fancybox-button, .fancybox-show-toolbar .fancybox-toolbar {
  opacity: 1;
  transition: opacity .25s,visibility 0s;
  visibility: visible; }

.fancybox-infobar {
  color: #ccc;
  font-size: 13px;
  -webkit-font-smoothing: subpixel-antialiased;
  height: 44px;
  left: 0;
  line-height: 44px;
  min-width: 44px;
  mix-blend-mode: difference;
  padding: 0 10px;
  pointer-events: none;
  text-align: center;
  top: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.fancybox-toolbar {
  right: 0;
  top: 0; }

.fancybox-stage {
  direction: ltr;
  overflow: visible;
  -webkit-transform: translateZ(0);
  z-index: 99994; }

.fancybox-is-open .fancybox-stage {
  overflow: hidden; }

.fancybox-slide {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: none;
  height: 100%;
  left: 0;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: absolute;
  text-align: center;
  top: 0;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  white-space: normal;
  width: 100%;
  z-index: 99994; }

.fancybox-slide:before {
  content: "";
  display: inline-block;
  height: 100%;
  margin-right: -.25em;
  vertical-align: middle;
  width: 0; }

.fancybox-is-sliding .fancybox-slide, .fancybox-slide--current, .fancybox-slide--next, .fancybox-slide--previous {
  display: block; }

.fancybox-slide--next {
  z-index: 99995; }

.fancybox-slide--image {
  overflow: visible;
  padding: 44px 0; }

.fancybox-slide--image:before {
  display: none; }

.fancybox-slide--html {
  padding: 6px 6px 0; }

.fancybox-slide--iframe {
  padding: 44px 44px 0; }

.fancybox-content {
  background: #fff;
  display: inline-block;
  margin: 0 0 6px;
  max-width: 100%;
  overflow: auto;
  padding: 0;
  padding: 24px;
  position: relative;
  text-align: left;
  vertical-align: middle; }

.fancybox-slide--image .fancybox-content {
  -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: transparent;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: 0;
  margin: 0;
  max-width: none;
  overflow: visible;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-transform-origin: top left;
  transform-origin: top left;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 99995; }

.fancybox-can-zoomOut .fancybox-content {
  cursor: zoom-out; }

.fancybox-can-zoomIn .fancybox-content {
  cursor: zoom-in; }

.fancybox-can-drag .fancybox-content {
  cursor: -webkit-grab;
  cursor: grab; }

.fancybox-is-dragging .fancybox-content {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.fancybox-container [data-selectable=true] {
  cursor: text; }

.fancybox-image, .fancybox-spaceball {
  background: transparent;
  border: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.fancybox-spaceball {
  z-index: 1; }

.fancybox-slide--html .fancybox-content {
  margin-bottom: 6px; }

.fancybox-slide--iframe .fancybox-content, .fancybox-slide--map .fancybox-content, .fancybox-slide--video .fancybox-content {
  height: 100%;
  margin: 0;
  overflow: visible;
  padding: 0;
  width: 100%; }

.fancybox-slide--video .fancybox-content {
  background: #000; }

.fancybox-slide--map .fancybox-content {
  background: #e5e3df; }

.fancybox-slide--iframe .fancybox-content {
  background: #fff;
  height: calc(100% - 44px);
  margin-bottom: 44px; }

.fancybox-iframe, .fancybox-video {
  background: transparent;
  border: 0;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%; }

.fancybox-iframe {
  vertical-align: top; }

.fancybox-error {
  background: #fff;
  cursor: default;
  max-width: 400px;
  padding: 40px;
  width: 100%; }

.fancybox-error p {
  color: #444;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0; }

.fancybox-button {
  background: rgba(30, 30, 30, 0.6);
  border: 0;
  border-radius: 0;
  cursor: pointer;
  display: inline-block;
  height: 44px;
  margin: 0;
  outline: none;
  padding: 10px;
  transition: color .2s;
  vertical-align: top;
  width: 44px; }

.fancybox-button, .fancybox-button:link, .fancybox-button:visited {
  color: #ccc; }

.fancybox-button:focus, .fancybox-button:hover {
  color: #fff; }

.fancybox-button.disabled, .fancybox-button.disabled:hover, .fancybox-button[disabled], .fancybox-button[disabled]:hover {
  color: #888;
  cursor: default; }

.fancybox-button svg {
  display: block;
  overflow: visible;
  position: relative;
  shape-rendering: geometricPrecision; }

.fancybox-button svg path {
  fill: transparent;
  stroke: currentColor;
  stroke-linejoin: round;
  stroke-width: 3; }

.fancybox-button--pause svg path:nth-child(1), .fancybox-button--play svg path:nth-child(2) {
  display: none; }

.fancybox-button--play svg path, .fancybox-button--share svg path, .fancybox-button--thumbs svg path {
  fill: currentColor; }

.fancybox-button--share svg path {
  stroke-width: 1; }

.fancybox-navigation .fancybox-button {
  height: 38px;
  opacity: 0;
  padding: 6px;
  position: absolute;
  top: 50%;
  width: 38px; }

.fancybox-show-nav .fancybox-navigation .fancybox-button {
  transition: opacity .25s,visibility 0s,color .25s; }

.fancybox-navigation .fancybox-button:after {
  content: "";
  left: -25px;
  padding: 50px;
  position: absolute;
  top: -25px; }

.fancybox-navigation .fancybox-button--arrow_left {
  left: 6px; }

.fancybox-navigation .fancybox-button--arrow_right {
  right: 6px; }

.fancybox-close-small {
  background: transparent;
  border: 0;
  border-radius: 0;
  color: #555;
  cursor: pointer;
  height: 44px;
  margin: 0;
  padding: 6px;
  position: absolute;
  right: 0;
  top: 0;
  width: 44px;
  z-index: 10; }

.fancybox-close-small svg {
  fill: transparent;
  opacity: .8;
  stroke: currentColor;
  stroke-width: 1.5;
  transition: stroke .1s; }

.fancybox-close-small:focus {
  outline: none; }

.fancybox-close-small:hover svg {
  opacity: 1; }

.fancybox-slide--iframe .fancybox-close-small, .fancybox-slide--image .fancybox-close-small, .fancybox-slide--video .fancybox-close-small {
  color: #ccc;
  padding: 5px;
  right: -12px;
  top: -44px; }

.fancybox-slide--iframe .fancybox-close-small:hover svg, .fancybox-slide--image .fancybox-close-small:hover svg, .fancybox-slide--video .fancybox-close-small:hover svg {
  background: transparent;
  color: #fff; }

.fancybox-is-scaling .fancybox-close-small, .fancybox-is-zoomable.fancybox-can-drag .fancybox-close-small {
  display: none; }

.fancybox-caption {
  bottom: 0;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  line-height: 1.5;
  padding: 25px 44px;
  right: 0; }

.fancybox-caption:before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAEtCAQAAABjBcL7AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAHRJREFUKM+Vk8EOgDAIQ0vj/3+xBw8qIZZueFnIKC90MCAI8DlrkHGeqqGIU6lVigrBtpCWqeRWoHDNqs0F7VNVBVxmHRlvoVqjaYkdnDIaivH2HqZ5+oZj3JUzWB+cOz4G48Bg+tsJ/tqu4dLC/4Xb+0GcF5BwBC0AA53qAAAAAElFTkSuQmCC);
  background-repeat: repeat-x;
  background-size: contain;
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: -25px;
  z-index: -1; }

.fancybox-caption:after {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  content: "";
  display: block;
  left: 44px;
  position: absolute;
  right: 44px;
  top: 0; }

.fancybox-caption a, .fancybox-caption a:link, .fancybox-caption a:visited {
  color: #ccc;
  text-decoration: none; }

.fancybox-caption a:hover {
  color: #fff;
  text-decoration: underline; }

.fancybox-loading {
  -webkit-animation: a .8s infinite linear;
  animation: a .8s infinite linear;
  background: transparent;
  border: 6px solid rgba(99, 99, 99, 0.5);
  border-radius: 100%;
  border-top-color: #fff;
  height: 60px;
  left: 50%;
  margin: -30px 0 0 -30px;
  opacity: .6;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 60px;
  z-index: 99999; }

@-webkit-keyframes a {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@keyframes a {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

.fancybox-animated {
  transition-timing-function: cubic-bezier(0, 0, 0.25, 1); }

.fancybox-fx-slide.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0); }

.fancybox-fx-slide.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0); }

.fancybox-fx-slide.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: translateZ(0);
  transform: translateZ(0); }

.fancybox-fx-fade.fancybox-slide--next, .fancybox-fx-fade.fancybox-slide--previous {
  opacity: 0;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }

.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1; }

.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: scale3d(1.5, 1.5, 1.5);
  transform: scale3d(1.5, 1.5, 1.5); }

.fancybox-fx-zoom-in-out.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5); }

.fancybox-fx-zoom-in-out.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: scaleX(1);
  transform: scaleX(1); }

.fancybox-fx-rotate.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: rotate(-1turn);
  transform: rotate(-1turn); }

.fancybox-fx-rotate.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: rotate(1turn);
  transform: rotate(1turn); }

.fancybox-fx-rotate.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg); }

.fancybox-fx-circular.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0); }

.fancybox-fx-circular.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0); }

.fancybox-fx-circular.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: scaleX(1) translateZ(0);
  transform: scaleX(1) translateZ(0); }

.fancybox-fx-tube.fancybox-slide--previous {
  -webkit-transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
  transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg); }

.fancybox-fx-tube.fancybox-slide--next {
  -webkit-transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
  transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg); }

.fancybox-fx-tube.fancybox-slide--current {
  -webkit-transform: translateZ(0) scale(1);
  transform: translateZ(0) scale(1); }

.fancybox-share {
  background: #f4f4f4;
  border-radius: 3px;
  max-width: 90%;
  padding: 30px;
  text-align: center; }

.fancybox-share h1 {
  color: #222;
  font-size: 35px;
  font-weight: 700;
  margin: 0 0 20px; }

.fancybox-share p {
  margin: 0;
  padding: 0; }

.fancybox-share__button {
  border: 0;
  border-radius: 3px;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  margin: 0 5px 10px;
  min-width: 130px;
  padding: 0 15px;
  text-decoration: none;
  transition: all .2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap; }

.fancybox-share__button:link, .fancybox-share__button:visited {
  color: #fff; }

.fancybox-share__button:hover {
  text-decoration: none; }

.fancybox-share__button--fb {
  background: #3b5998; }

.fancybox-share__button--fb:hover {
  background: #344e86; }

.fancybox-share__button--pt {
  background: #bd081d; }

.fancybox-share__button--pt:hover {
  background: #aa0719; }

.fancybox-share__button--tw {
  background: #1da1f2; }

.fancybox-share__button--tw:hover {
  background: #0d95e8; }

.fancybox-share__button svg {
  height: 25px;
  margin-right: 7px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: 25px; }

.fancybox-share__button svg path {
  fill: #fff; }

.fancybox-share__input {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #d7d7d7;
  border-radius: 0;
  color: #5d5b5b;
  font-size: 14px;
  margin: 10px 0 0;
  outline: none;
  padding: 10px 15px;
  width: 100%; }

.fancybox-thumbs {
  background: #fff;
  bottom: 0;
  display: none;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 2px 2px 4px;
  position: absolute;
  right: 0;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  width: 212px;
  z-index: 99995; }

.fancybox-thumbs-x {
  overflow-x: auto;
  overflow-y: hidden; }

.fancybox-show-thumbs .fancybox-thumbs {
  display: block; }

.fancybox-show-thumbs .fancybox-inner {
  right: 212px; }

.fancybox-thumbs > ul {
  font-size: 0;
  height: 100%;
  list-style: none;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  position: relative;
  white-space: nowrap;
  width: 100%; }

.fancybox-thumbs-x > ul {
  overflow: hidden; }

.fancybox-thumbs-y > ul::-webkit-scrollbar {
  width: 7px; }

.fancybox-thumbs-y > ul::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }

.fancybox-thumbs-y > ul::-webkit-scrollbar-thumb {
  background: #2a2a2a;
  border-radius: 10px; }

.fancybox-thumbs > ul > li {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  cursor: pointer;
  float: left;
  height: 75px;
  margin: 2px;
  max-height: calc(100% - 8px);
  max-width: calc(50% - 4px);
  outline: none;
  overflow: hidden;
  padding: 0;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  width: 100px; }

.fancybox-thumbs-loading {
  background: rgba(0, 0, 0, 0.1); }

.fancybox-thumbs > ul > li {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover; }

.fancybox-thumbs > ul > li:before {
  border: 4px solid #4ea7f9;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 99991; }

.fancybox-thumbs .fancybox-thumbs-active:before {
  opacity: 1; }

@media (max-width: 800px) {
  .fancybox-thumbs {
    width: 110px; }
  .fancybox-show-thumbs .fancybox-inner {
    right: 110px; }
  .fancybox-thumbs > ul > li {
    max-width: calc(100% - 10px); } }
